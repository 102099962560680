import React from 'react'
import { graphql } from 'gatsby'
import MarkdownToHTML from '../components/MarkdownToHTML/MarkdownToHTML'
import SEO from '../components/SEO/SEO'

export default function LinksPage({ data }) {
  const html = data.markdownRemark.html
  return (
    <>
      <SEO title="Links" route="/links"></SEO>
      {html ? <MarkdownToHTML content={html} className="artist" /> : null}
    </>
  )
}

export const infosPageQuery = graphql`
  {
    markdownRemark(fileAbsolutePath: { regex: "/content/pages/infos.md/" }) {
      html
    }
  }
`
